<template>
  <div class="header">
    <div
      @click="clickItem('/ai')"
      :style="activeStyleAi"
    >AI Tool</div>
    <!-- <div
      @click="clickItem('/about')"
      :style="activeStyleAbout"
    >About Us</div> -->
    <div
      @click="clickItem('/advertise')"
      :style="activeStyleAd"
    >Advertise</div>
    <div
      @click="clickItem('/home')"
      :style="activeStyleHome"
    >Home</div>
  </div>
</template>

<script>
import Bus from '../common/Bus.js'

export default {
  name: 'Header',
  components: {

  },
  data () {
    return {
      activeStyle: { color: "rgb(229, 47, 130)" },
      activePath: ""
    }
  },
  computed: {
    activeStyleAi () {
      if (this.activePath.indexOf('/ai') !== -1) {
        return this.activeStyle
      }
      return {}
    },
    activeStyleAbout () {
      if (this.activePath.indexOf('/about') !== -1) {
        return this.activeStyle
      }
      return {}
    },
    activeStyleAd () {
      if (this.activePath.indexOf('/advertise') !== -1) {
        return this.activeStyle
      }
      return {}
    },
    activeStyleHome () {
      if (this.activePath.indexOf('/home') !== -1) {
        return this.activeStyle
      }
      return {}
    }
  },
  created () {
    console.log('------->', this.$route.path)
    this.activePath = this.$route.path;

    Bus.$on('click-link', (val) => {
      console.log('click link------->', val)
      if (this.$route.path.indexOf(val) !== -1) {
        return
      }

      this.activePath = val;
      this.$router.replace(val);
    })
  },
  methods: {
    clickItem (path) {
      console.log("path--->", path)
      if (this.$route.path.indexOf(path) !== -1) {
        return
      }
      this.activePath = path
      this.$router.replace(path)
    }
  }
}
</script>

<style>
.header {
  width: 90%;
  padding-right: 80px;
  color: white;
  font-size: 42px;

  display: flex;
  flex-direction: row-reverse;
  /* justify-content: flex-end; */
  align-items: center;
  flex-grow: 1;

  /* background-color: black; */
}

.header div {
  margin-left: 40px;
}
</style>