import vue from 'vue'
import VueRouter from 'vue-router'

vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/home",
    },
    {
        path: "/home",
        component: () => import('../views/Home')
    }, {
        path: "/advertise",
        component: () => import('../views/Advertise')
    }
    , {
        path: "/about",
        component: () => import('../views/About')
    }
    , {
        path: "/ai",
        component: () => import('../views/AI')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router