<template>
  <div class="footer clearfix">
    <div class="foot-content">
      <div class="left">
        <div class="title">Our Solutions</div>
        <ul>
          <li>Advertise</li>
          <li>Monetize</li>
          <li>Publish</li>
        </ul>
      </div>
      <div class="middle">
        <div class="title">Link</div>
        <ul>
          <li><a @click="clickLink('/about')">About us</a></li>
          <li><a @click="clickLink('/contact')">Contact us</a></li>
        </ul>
      </div>
      <div class="right">
        <div class="title">Company</div>
        <ul>
          <li>Name:FasionAI Holdings HK Limited</li>
          <li>
            Address:Unit 507, 5/F., New East Ocean Centre, No. 9 Science Museum Road, Tsim Sha Tsui East, Kowloon, Hong Kong
          </li>
          <li>Contact email:techfunshine@gmail.com</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '../common/Bus'

export default {
  name: 'Footer',
  components: {

  },
  data () {
    return {
      activePath: ""
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    clickLink (path) {
      console.log('-------' + path)
      Bus.$emit('click-link', path)
    },
  }
}
</script>

<style>
.footer {
  height: 300px;
  color: white;
  font-size: 22px;
  line-height: 40px;
  background-color: #4949d7;
}

.foot-content {
  width: 85%;
  margin: 40px auto 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* flex-grow: 1; */
}

.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.foot-content .title {
  font-weight: bold;
  margin-bottom: 0px;
}

.foot-content a {
  text-decoration: none;
  color: white;
}

.foot-content a:hover {
  color: blue;
}

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.left {
  width: 15%;
}

.middle {
  width: 15%;
}

.right {
  width: 60%;
}
</style>