<template>
  <div id="app">
    <div class="content">
      <Header class="header"></Header>
      <router-view></router-view>
      <Footer class="footer" />
    </div>
  </div>
</template>

<script>

import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Header, Footer
  }
}
</script>

<style>
@import 'assets/css/normalize.css';

#app {
  width: 100%;
  /* background-color: black; */
}

#app > .content {
  width: 1440px;
  margin: auto;
  position: relative;
}

.content > .header {
  position: absolute;
  top: 10px;
  left: 0px;
}
</style>
